.app {
  background: var(--dark-color);
  font-family: var(--font-base);
}

.app__container {
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 600px) {
    padding: 5rem 1rem
  }
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  width: fit-content;
  margin: 0 auto;
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--lightest-color);
  text-transform: capitalize;

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.75rem;
  }
}

.p-text {
  font-size: 1rem;
  text-align: left;
  color: var(--dark-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1.2rem;
  font-weight: 900;
  color: var(--dark-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.span-highlight {
  color: var(--brand-color);
}

.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  padding: 1rem;

  div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // background-color: var(--lightest-color);
    border: 3px solid;
    border-color: var(--brand-color);
    margin: 0.25rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 15px;
      height: 15px;
      color: var(--lightest-color);
    }

    &:hover {
      background-color: var(--brand-color);

      svg {
        color: var(--lightest-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--lightest-color);
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--brand-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 700px) {

  .app__navigation,
  .app__social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

.brand-line {
  width: 50%;
  height: 2px;
  border-width: 0;
  background-color: var(--brand-color)
}

.particles-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 70%;
}

.particles-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 70%;
}

.particles-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

