// .app__about {
//     flex: 1;
//     width: 100%;
//     flex-direction: column;
//   }
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .app__profile-item {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    background-color: var(--lightest-color);
    border-radius: 1rem;
  
    img {
      width: 100%;
      height: 170px;
      border-radius: 15px;
      object-fit: cover;
      // box-shadow: 5px 10px 8px #00000045;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }

  .paragraph {
    color: var(--light-color);
    width: 50%;
    text-align: left;
    margin: 1rem auto 0 auto;
    font-size: 1.25rem;

    @media screen and (max-width: 1100px) {
      width: 80%;

    }
  }
