/////////////////////////////// MAIN //////////////////////////////
.app__header {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  padding: 0rem 2rem 2rem 2rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }

  .header-info {
    width: 100%;
    z-index: 1;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    h1 {
      font-family: 'Montserrat', sans-serif;
      font-size: 4rem;
      font-weight: 800;
      color: var(--lightest-color);
      text-transform: capitalize;
      text-align: center;
      line-height: 1.1;

      @media screen and (max-width: 600px) {
        font-size: 3.25rem;
      }
    }

    p {
      font-family: 'Playfair Display', serif;
      font-weight: 900;
      font-size: 2.5rem;
      text-align: center;
      color: var(--light-color);
      line-height: 1.5;

      @media screen and (max-width: 550px) {
        font-size: 1.75rem;
      }
    }
  }

  .down-arrow {
    position: absolute;
    bottom: 1rem;
    cursor: pointer;
  }
}

.particles-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 70%;
}

.particles-container {
  position: relative;
}