@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #f2f5f9;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --white-color: #ffffff;

  --brand-color: #8EC1D6;
  --darkest-color: #263141;
  --dark-color: #3D4B5F;
  --mid-color: #8799AF;
  --light-color: #CBD5E1;
  --lightest-color: #FFFFFF;

  --first-gradient: linear-gradient(90deg, hsla(215, 22%, 31%, 1) 0%, hsla(216, 26%, 20%, 1) 100%);

  --angle-gradient:  linear-gradient(45deg, hsla(216, 26%, 20%, 1) 49%, hsla(215, 22%, 31%, 1) 79%, hsla(213, 20%, 61%, 1) 100%);

  --reverse-gradient: linear-gradient(225deg, hsla(216, 26%, 20%, 1) 49%, hsla(215, 22%, 31%, 1) 79%, hsla(213, 20%, 61%, 1) 100%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  user-select: none;
  
}

a {
  color: white;
  text-decoration: none;
}