///////////////////// NAVBAR /////////////////////

.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;
    // background: #FFFFFF;
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    z-index: 2;
}

///////////////////// LOGO /////////////////////
.app__navbar-logo {

    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

    a {
        position: absolute;
        color: var(--lightest-color);
        font-size: 2rem;
        font-weight: bolder;

        span {
            font-weight: lighter;
            color: var(--brand-color);
        }

        @media screen and (max-width: 450px) {
            font-size: 1.25rem;
        }
    }
}


///////////////////// LINKS /////////////////////
.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;

    li {
        margin: 0 1rem;
        font-size: 1rem;
        cursor: pointer;

        flex-direction: column;

        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;

            margin-bottom: 5px;
        }

        a {
            color: var(--lightest-color);
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover {
                color: var(--brand-color);
            }
        }

        &:hover {
            div {
                background: var(--lightest-color);
            }
        }
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
}

///////////////////// Collapsed Menu /////////////////////
.app__navbar-menu {
    width: 45px;
    height: 45px;
    border-radius: 0.5rem;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 70%;
        height: 70%;
        color: var(--lightest-color);
        cursor: pointer;
    }

    .mobile-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 12rem;
        height: 12rem;
        border-radius: 0.5rem;
        padding: 0 1rem;
        background-color: var(--darkest-color);
        position: absolute;
        top: 3.5rem;
        right: 0.5rem;

        ul {
            list-style: none;
            text-align: right;

            li a {
                color: var(--lightest-color);
                text-transform: capitalize;
                font-size: 1.5rem;

                &:hover {
                    border-bottom: 3px solid var(--brand-color)
                }
            }

            ;
        }
    }

    @media screen and (min-width: 1100px) {
        display: none;
    }
}